import {SbBlokData, storyblokEditable, useStoryblokApi} from "@storyblok/react";
import classNames from "classnames";
import {string} from "prop-types";
import {richTextResolver} from "@storyblok/richtext";

type IProps = {
    blok: {
        title: string,
        subtitle: string,
        intro: any|string,
        size: string
    } & SbBlokData
}

export default function InlineHeading(props: IProps) {
    const blok = props.blok;
    const { render } = richTextResolver()


    return (
        <div
            data-aos={blok?.aos}
            data-aos-delay={blok?.aos_delay}
            className="mb-4" {...storyblokEditable(blok)}>
            {blok.subtitle &&
                <p className="text-base mb-0 font-semibold text-secondary">
                    {blok.subtitle}
                </p>}

            {blok.title &&
                <h3
                    className={classNames(
                        "mt-2 font-bold leading-8 tracking-tight text-gray-900",
                        blok.size || "text-xl sm:text-2xl"
                    )}>
                    {blok.title}
                </h3>
            }

            {blok.intro &&
                <div
                    className="text-lg text-gray-700"
                    dangerouslySetInnerHTML={{
                        __html: (typeof blok.intro == 'string') ? (blok.intro as string) : (render((blok.intro )) as string)
                    }}
                >

                </div>
            }
        </div>

    )
}